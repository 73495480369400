var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content-box",style:({
 
    backgroundImage: 'url(' + _vm.pagebg1 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  })},[_c('div',{staticClass:"info-banner",style:({
      backgroundImage: 'url(' + _vm.bannerImg + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    })},[_c('HeadMain',{staticClass:"no-fixed"})],1),_c('div',{staticClass:"inheritor-box"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"mail-certificates"},[_c('certificate',{staticClass:"tranform-limi"})],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{staticClass:"txt-search",class:{ inacitve: true },on:{"click":_vm.printIMG}},[_vm._v("下载证书")])],1)]),_c('botto-com',{staticClass:"bottom-white no-fixed"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cert-pass-con"},[_c('p',[_vm._v(" 传承人是直接参与非物质文化遗产传承、使非物质文化遗产能够沿袭的个人或群体(团体)。非物质文化遗产最重要的活态载体 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',[_c('i',{staticClass:"tit-i1"}),_c('i',{staticClass:"tit-red"},[_vm._v("认证")]),_vm._v("通过 "),_c('i',{staticClass:"tit-i2"})])])}]

export { render, staticRenderFns }