<template>
  <div
    class="page-content-box"
    :style="{
	  
      backgroundImage: 'url(' + pagebg1 + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain'
    }"
  >
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <HeadMain class="no-fixed"></HeadMain>
    </div>
    <div class="inheritor-box">
      <div class="cert-pass-con">
        <p>
          传承人是直接参与非物质文化遗产传承、使非物质文化遗产能够沿袭的个人或群体(团体)。非物质文化遗产最重要的活态载体
        </p>
      </div>
      <div class="custon-line">
        <span>
          <i class="tit-i1"></i>
          <i class="tit-red">认证</i>通过
          <i class="tit-i2"></i>
        </span>
      </div>
      <div class="mail-certificates">
        <certificate class="tranform-limi"></certificate>
      </div>
      <div style="text-align: center;">
        <el-button class="txt-search" :class="{ inacitve: true }" @click="printIMG">下载证书</el-button>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
import certificate from '../../components/certificate'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  components: {
    HeadMain,
    BottoCom,
    certificate
  },
  data() {
    return {
      active: 1,
      bannerImg: require('../../assets/images/bg6.jpg'),
      pagebg1: require('../../assets/images/page-bg1.jpg'),
      zsmini: require('../../assets/images/zs-mini.png')
    }
  },
  methods: {
    exportPDF() {
      let name = '认证证书'
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      console.log('scrollTop=' + scrollTop)
      document.body.scrollTop = 0 // IE的
      document.documentElement.scrollTop = 0 // 其他
      let shareContent = document.getElementsByClassName('tranform-limi')[0], //需要截图的包裹的（原生的）DOM 对象
        width = shareContent.clientWidth, //获取dom 宽度
        height = shareContent.clientHeight, //获取dom 高度
        canvas = document.createElement('canvas'), //创建一个canvas节点
        scale = 2 //定义任意放大倍数 支持小数
      canvas.width = width * scale //定义canvas 宽度 * 缩放
      canvas.height = height * scale //定义canvas高度 *缩放
      canvas.style.width = shareContent.clientWidth * scale + 'px'
      canvas.style.height = shareContent.clientHeight * scale + 'px'
      canvas.getContext('2d').scale(scale, scale) //获取context,设置scale
      let opts = {
        scale: scale, // 添加的scale 参数
        canvas: canvas, //自定义 canvas
        logging: false, //日志开关，便于查看html2canvas的内部执行流程
        width: width, //dom 原始宽度
        height: height,
        useCORS: true // 【重要】开启跨域配置
      }

      html2canvas(shareContent, opts).then(() => {
        var contentWidth = canvas.width
        var contentHeight = canvas.height
        //一页pdf显示html页面生成的canvas高度;
        var pageHeight = (contentWidth / 592.28) * 841.89
        //未生成pdf的html页面高度
        var leftHeight = contentHeight
        //页面偏移
        var position = 0
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28
        var imgHeight = (592.28 / contentWidth) * contentHeight
        var pageData = canvas.toDataURL('image/jpeg', 1.0)
        var PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        document.body.scrollTop = scrollTop // IE的
        document.documentElement.scrollTop = scrollTop // 其他
        PDF.save(name + '.pdf') // 这里是导出的文件名
      })
    },
    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    printIMG() {
      let name = '认证证书'
      document.body.scrollTop = 0 // IE的
      document.documentElement.scrollTop = 0 // 其他
      let a = document.createElement('a')
      let shareContent = document.getElementsByClassName('tranform-limi')[0], //需要截图的包裹的（原生的）DOM 对象
        width = shareContent.clientWidth, //获取dom 宽度
        height = shareContent.clientHeight, //获取dom 高度
        canvas = document.createElement('canvas'), //创建一个canvas节点
        scale = 3.55 //定义任意放大倍数 支持小数
      canvas.width = width*scale //定义canvas 宽度 * 缩放
      canvas.height = height * 3.65 //定义canvas高度 *缩放
      canvas.style.width = shareContent.clientWidth * scale + 'px'
      canvas.style.height = shareContent.clientHeight * scale + 'px'
      canvas.getContext('2d').scale(scale, scale) //获取context,设置scale
      let opts = {
        scale: scale, // 添加的scale 参数
        canvas: canvas, //自定义 canvas
        logging: false, //日志开关，便于查看html2canvas的内部执行流程
        width: canvas.width, //dom 原始宽度
        height: canvas.height,
        useCORS: true // 【重要】开启跨域配置
      }

      html2canvas(shareContent, opts).then(canvas => {
        let dom = document.body.appendChild(canvas)
        dom.style.display = 'none'
        a.style.display = 'none'
        document.body.removeChild(dom)
        let blob = this.dataURLToBlob(dom.toDataURL('image/png'))
        a.setAttribute('href', URL.createObjectURL(blob))
        a.setAttribute('download', name + '.png')
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(blob)
        document.body.removeChild(a)
      })
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped></style>
